import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Projects from "../components/Projects"
import SEO from "../components/SEO"

const ProjectsPage = ({ data }) => {
  return (
    <Layout>
      <SEO title='Sponsors' description='This is the sponsors page' />
      <section className='projects-page'>
        <Projects projects={data.allMarkdownRemark.nodes} title='all sponsors' />
      </section>
    </Layout>
  )
}
export const query = graphql`
  {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(projects)/"}}, sort: {fields: frontmatter___id, order: ASC}) {
      nodes {
        frontmatter {
          github
          id
          description
          title
          url
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          stack {
            title
          }
        }
      }
    }
  }
`

export default ProjectsPage
